<template>
  <div>
   <TableWrap @callback="handlePagination" :total="total">
        <template slot="tablesearch">
            <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
        </template>
        <template slot="tableOperate">
           <el-button type="primary" size="small" @click="handleCreate">新增</el-button>
        </template>
        <el-table :data="tableData" :header-cell-style="{background:'#FAFAFA'}" v-loading="loading">
            <el-table-column prop="name" label="对象名称"> </el-table-column>
            <el-table-column prop="code" label="对象编码"> </el-table-column>
            <el-table-column prop="appCode" label="应用编码"> </el-table-column>
            <el-table-column prop="tableName" label="数据库表名"> </el-table-column>
            <el-table-column label="状态">
                <template v-slot="scope">
                    {{  scope.row.status===0?'有效':'无效' }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
            <el-table-column label="操作" width="210" align="center">
                <template v-slot="scope">
                    <el-button type="text" size="small" plain @click="handleEdit(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" plain @click="handleDetail(scope.row.id)">详情</el-button>
                    <el-button type="text" size="small" plain @click="handleDelete(scope.row.id)">
                        <span style="color:#F5222D">
                            删除
                        </span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </TableWrap>
  </div>
</template>

<script>
import tableMixIn from '@/mixIn/tableMixIn'
import { reqList, reqDel } from '@/api/metadataManagement/metadataManagement.js'
export default {
    name: 'ivyobject',
    mixins: [tableMixIn],
    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'roleName', label: '对象名称/编码/应用编码' }
            ],
            tableData: [
                { name: 'x', codeO: 'y', codeA: 'z', sqlN: 'k', eD: '2020-1-9', cD: '2019-1-5' }
            ],
            reqTableData: reqList,
        }
    },
    created () {
        this.getTableData()
    },
    methods: {
        handleCreate () {
            this.$router.push({
                name: 'ivyobjectAE',
                // query: { id: id, name: name, appCode: appCode, dictionaryCode: code }
                query: { type: 'add' }
            })
        },
        handleEdit (id) {
            this.$router.push({
                name: 'ivyobjectAE',
                // query: { id: id, name: name, appCode: appCode, dictionaryCode: code }
                query: { type: 'edit', id: id }
            })
        },
        handleDetail (id) {
            this.$router.push({
                name: 'ivyobjectD',
                query: { id: id }
            })
        },
        handleDelete (id) {
            this.$confirm('删除后不可恢复，确定要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                reqDel(id).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('删除成功')
                        this.getTableData()
                    }
                })
            })
        },
        getTable () {
            const { query, current, size } = this
            reqList({ ...query, current, size }).then((res) => {

            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
